<template>
  <div
    id="contents"
    :class="{
      product_release: tabIndex == 0 || tabIndex == 3,
      product_release_register: tabIndex == 1,
      product_order_regist: tabIndex == 2,
      output_order_regist: tabIndex == 2,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <component
          :is="asideComponent"
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToOutputPage'"
          :options="menuFilter"
        >
        </component>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            href="#">관리</a
          > -->
          <!-- <button class="btn_admin" :disabled="!managementMode">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            :class="{ active: tabIndex == 0 }"
            v-if="isPermissionOn && systemCompany.id != 17"
          >
            <a @click="SetOpenTabIndex(0)">수주 기준 출고</a>
          </li>
          <li
            :class="{ active: tabIndex == 1 }"
            v-if="isPermissionOn && systemCompany.id != 17"
          >
            <a @click="SetOpenTabIndex(1)">제품 기준 출고</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">출고 등록</a>
          </li>
          <li
            :class="{ active: isPermissionOn ? tabIndex == 3 : tabIndex == 0 }"
          >
            <a @click="SetOpenTabIndex(3)">매출 조회 및 관리</a>
          </li>
        </ul>
      </div>
      <!-- <sales-base-form
        v-if="isPermissionOn ? tabIndex == 0 : tabIndex == -1"
      ></sales-base-form>
      <product-base-form v-if="tabIndex == 1"></product-base-form>
      <new-output-form v-if="tabIndex == 2"></new-output-form> -->
      <component
        :is="dynamicComponent"
        v-if="isPermissionOn ? tabIndex == 0 : tabIndex == -1"
      >
      </component>
      <component :is="dynamicComponent2" v-if="tabIndex == 1"> </component>
      <component :is="dynamicComponent3" v-if="tabIndex == 2"> </component>
      <output-management-form
        v-if="isPermissionOn ? tabIndex == 3 : tabIndex == 0"
      ></output-management-form>
      <!-- <sales-new-form v-if="tabIndex == 1"></sales-new-form> -->
    </div>
  </div>
</template>

<script>
// import SalesBaseForm from '@/views/forms/Product/Output/SalesBaseForm';
// import ProductBaseForm from '@/views/forms/Product/Output/ProductBaseForm';
import OutputManagementForm from '@/views/forms/Product/Output/OutputManagementForm';
// import NewOutputForm from '@/views/forms/Product/Output/NewOutputForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import SpinnerModal from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
// import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, SpinnerModal, ModalMixin, FavoriteMixin],
  components: {
    // SalesBaseForm,
    // ProductBaseForm,
    OutputManagementForm,
    // NewOutputForm,
    // AsideSelectric,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromOutputPage',
      tabIndex: 'getOpenTabIndexFromOutputPage',
      systemCompany: 'getSystemCompany',
    }),
    dynamicComponent() {
      if (Number(localStorage.getItem('saupKey')) == 18) {
        return () =>
          import('@/views/forms/Product/Output/18/BomnaeSalesBaseForm.vue');
      } else
        return () => import('@/views/forms/Product/Output/SalesBaseForm.vue');
    },
    dynamicComponent2() {
      if (Number(localStorage.getItem('saupKey')) == 18) {
        return () =>
          import('@/views/forms/Product/Output/18/BomnaeProducBasetFrom.vue');
      } else
        return () => import('@/views/forms/Product/Output/ProductBaseForm.vue');
    },
    dynamicComponent3() {
      if (Number(localStorage.getItem('saupKey')) == 18) {
        return () =>
          import('@/views/forms/Product/Output/18/BomnaeNewOutputForm.vue');
      } else if (Number(localStorage.getItem('saupKey')) == 17) {
        return () =>
          import('@/views/forms/Product/Output/17/CNSNewOutputForm.vue');
      } else if (Number(localStorage.getItem('saupKey')) == 21) {
        return () =>
          import('@/views/forms/Product/Output/21/DaejinNewOutputForm.vue');
      } else
        return () => import('@/views/forms/Product/Output/NewOutputForm.vue');
    },
    asideComponent() {
      if (Number(localStorage.getItem('saupKey')) == 17) {
        return () => import('@/layouts/components/17/CNSAsideSelectric');
      } else return () => import('@/layouts/components/AsideSelectric');
    },
    menuFilter() {
      let meunOption = [];

      if (Number(localStorage.getItem('saupKey')) == 17) {
        meunOption = [
          { title: '출고 등록', name: 'new_output' },
          { title: '매출 조회 및 관리', name: 'output_management' },
        ];
      } else {
        meunOption = [
          { title: '수주 기준 출고', name: 'sales_base_output' },
          { title: '제품 기준 출고', name: 'product_base_output' },
          { title: '출고 등록', name: 'new_output' },
          { title: '매출 조회 및 관리', name: 'output_management' },
        ];
      }
      return meunOption;
    },
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToOutputPage',
      SetOpenTabIndex: 'setOpenTabIndexToOutputPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitOutputPage');
      this.$route.meta.menu_init = false;
    }

    if (Number(localStorage.getItem('saupKey')) == 17) {
      this.SetOpenTabIndex(2);
    }
    // if (this.isPermissionOn) {
    //   console.log('this.isPermissionOn', this.isPermissionOn);
    //   this.SetOpenTabIndex(2);
    // }
  },
};
</script>

<style></style>
